<template>
  <div class="humanitarian">
    <div class="human-continar">
     <!-- <Projectmap></Projectmap> -->
     <!-- <Projectactive :activelist="activelist"></Projectactive> -->
     <!-- <Projectintroduct></Projectintroduct> -->
     <Projectnews :newslist="newslist" @childByValue="childByValue"></Projectnews>
    </div>
  </div>
</template>

<script>
// import Projectmap from './components/projectmap'
// import Projectactive from './components/projectactive'
import Projectintroduct from './components/projectintroduct.vue'
import Projectnews from './components/projectnews.vue'
import apiUrls from '@api'
export default {
  name: 'Humanitarian',
  components: {
    // Projectmap,
    // Projectactive,
    Projectintroduct,
    Projectnews
  },
  data () {
    return {
      newslist: [],
      activelist: []
    }
  },
  created () {
    this.getnewslist()
    this.getactivelist()
  },
  methods : {
    getnewslist () {
      apiUrls.gethumanitarian({node: 'rdxm_xwlb'}).then(res=>{
      this.newslist= res.results.data
      })
    },
    getactivelist () {
      apiUrls.gethumanitarian({node: 'rdxm_xmhd'}).then(res=>{
      this.activelist= res.results.data
      })
    },
    childByValue (childByValue) {
      this.newslist = childByValue
    }
  }
}
</script>

<style lang="less" scoped>
.title {
    margin-top: 20px;
    font-size: 24px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 16px;
}
.humanitarian {
    background-color:  #f5f5f5;
    .human-continar {
        width: 1200px;
        min-height: 400px;
        padding-bottom: 40px;
        margin: 0 auto;
        background-color: #f5f5f5;
        &::before {
            display: block;
            content: ".";
            clear: both;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }
    }
}
</style>
